@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "./variables.scss";
@import "./material.scss";
@import "./modals.scss";
@import "./helpers.scss";

/*****************************************    HACKS    **************************************************/
/* Required for 1st modal to be in focus after returning from second, stacked modal */
.modal {
  overflow: auto !important;
}
/* Required for toast notifications to be visible (name collision with toast and bootstrap) */
#toast-container > div {
  opacity: 1;
}
/********************************************************************************************************/

.me-1 {
  margin-right: 0.25rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 0.75rem !important;
}
.ms-4 {
  margin-left: 1.5rem !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}

.material-icons {
  font-size: 11pt;
  padding: 0px;
  margin: 0px;
  margin-left: 5px;
  margin-right: 5px;
}

.material-icons-outlined {
  font-size: 11pt;
  padding: 0px;
  margin: 0px;
  margin-left: 5px;
  margin-right: 5px;
}

.page {
  padding: 10px 10px 0 10px;
}

/* minus menu height  */
.grid-height {
  max-height: calc(100vh - 80px);
  min-height: 200px;
}

.btn-outline-primary {
  color: rgb(0, 76, 255);
  background: white;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.btn-outline-primary:hover {
  background: rgb(0, 76, 255);
  color: white;
}

.yellow {
  color: yellow;
}

.red {
  color: red;
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.blue {
  color: blue;
}

.magenta {
  color: magenta;
}

.orangeBackground {
  background: orange;
}

.greenBackground {
  background: lightgreen;
}

.red:hover {
  color: white;
  background: red;
}

.green:hover {
  color: white;
  background: green;
}

.redNote {
  color: red;
}

.greenNote {
  color: green;
}

.redNote:hover {
  color: red;
}

.greenNote:hover {
  color: green;
}

.strikeclass {
  text-decoration: line-through;
}

.boldText {
  font-weight: bold;
}

.wrapText {
  white-space: pre-wrap;
  text-align: left;
  max-width: 100%; /* needed to make the wrapping of full width words work */
}

.error {
  margin: 20px 100px;
  text-align: center;
  padding: 12px;
  border-style: solid;
  border-radius: 5px;
  border-color: rgb(253, 175, 175);
  border-width: 2px;
  color: #d8000c;
  background-color: #ffd2d2;
}
.error p {
  font-size: 1em;
  vertical-align: middle;
  display: inline;
}

.error-small {
  margin: 0px 10px;
  padding: 3px;
  text-align: center;
  border-style: solid;
  border-radius: 5px;
  border-color: rgb(253, 175, 175);
  border-width: 2px;
  color: #d8000c;
  background-color: #ffd2d2;
}
.error-small p {
  font-size: 1em;
  vertical-align: middle;
  display: inline;
}

.success {
  margin: 20px 100px;
  text-align: center;
  padding: 6px;
  border-style: solid;
  border-radius: 5px;
  border-color: rgb(213, 240, 166);
  border-width: 2px;
  color: #4f8a10;
  background-color: #dff2bf;
}
.success h4 {
  vertical-align: middle;
  display: inline;
}

.globalTreeclass {
  height: 250px;
  font-size: 0.8em;
  width: 100%;
  border: 1mm;
  border-style: ridge;
  overflow: hidden;
  margin: 15px, 15px, 15px, 15px;
}

.jobClass {
  display: inline-flex;
}

.jobString {
  display: inline-block;
  max-height: 36px;
  margin-left: 8px;
}

.jobStringText {
  overflow: hidden;
}

.redWhite {
  color: white;
  background: red;
}
