:root {
	--menu-blue: rgb(227, 242, 253);
	--menu-blue-darker: rgb(211, 234, 250);
	--title-blue: rgb(61, 98, 172);
	--bar-grey1: rgb(240, 240, 240);
	--bar-grey2: rgb(225, 225, 225);
	--bar-grey3: rgb(206, 206, 206);
	--bar-grey4: rgb(180, 180, 180);
	--bar-grey5: rgb(145, 145, 145);
	--light-blue: rgb(176, 211, 238);
	--light-blue-on: rgb(146, 198, 238);
	--button-grey: rgb(225, 225, 225);
	--button-grey-on: rgb(192, 192, 192);
	--button-dark-text: rgb(15, 15, 15);
	--bootstrap-blue: rgb(0, 123, 255);
	--bootstrap-blue-on: rgb(40, 96, 144);
	--warning-red: rgb(255, 115, 115);
}