/*****************************************    MATERIAL STYLE   ***********************************************/

/* Material Overrides */
.formFieldWidth300 .mat-form-field-infix {
	width: 300px;
}
.mat-form-field-wrapper {
	padding-bottom: -5px;
}

/* Material Colour Overrides */
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: var(--bootstrap-blue);
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
	color:var(--bootstrap-blue);
	background-color: var(--bootstrap-blue);
}
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
	background-color: var(--bootstrap-blue);
}
.mat-ripple-element {
	background: rgba(0, 123, 255, .4) !important;
}

/* ICONS */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }
