/* MODAL STYLES */

.modal-error .modal-dialog {
  width: 90%;
  max-width: 500px;
  margin: auto;
  margin-top: 25px;
  padding: 0;
  border: #d8000c;
  border-style: double;
  border-radius: 2mm;
}
.modal-edit .modal-dialog {
  width: 90%;
  max-width: 700px;
  height: 90%;
  max-height: calc(90%);
  margin: auto;
  margin-top: 25px;
  padding: 0;
}
.modal-edit2 .modal-dialog {
  width: 90%;
  max-width: 720px;
  height: 90%;
  max-height: calc(90%);
  margin: auto;
  padding: 0;
  margin-top: 250px;
}
.modal-edit3 .modal-dialog {
  width: 90%;
  max-width: 600px;
  height: 90%;
  max-height: calc(90%);
  margin: auto;
  padding: 0;
  margin-top: 100px;
}
.modal-xl .modal-dialog {
  width: 98%;
  max-width: calc(98%);
  height: 98%;
  max-height: calc(98%);
  margin: auto;
  padding: 0;
}
.modal-center .modal-dialog {
  width: 80%;
  max-width: calc(80%);
  margin: auto;
  padding: 0;
  position: relative;
  top: 10%;
  bottom: 15%;
  transform: translateY(-50%);
}
.modal-center .modal-body {
  height: 550px;
}
.modal-1000 .modal-dialog {
  width: 95%;
  max-width: 1000px;
  height: 95%;
  max-height: calc(95%);
  margin: auto;
  margin-top: 25px;
  padding: 0;
}
.modal-1200 .modal-dialog {
  width: 95%;
  max-width: 1200px;
  height: 95%;
  max-height: calc(95%);
  margin: auto;
  margin-top: 25px;
  padding: 0;
}
.modal-pdf .modal-dialog {
  width: 950px;
  max-width: calc(99%);
  height: 99%;
  max-height: calc(99%);
  margin: auto;
  padding: 0;
}
.modal-fullScreen .modal-dialog {
  width: 97%;
  max-width: calc(97%);
  height: 97%;
  max-height: calc(97%);
  margin: auto;
  padding: 0;
  margin-top: 10px;
}
.modal-img-select .modal-dialog {
  width: 98%;
  max-width: 930px;
  /* height: 95%;
	max-height: calc(95%); */
  margin: auto;
  margin-top: 25px;
  padding: 0;
}
.modal-stateStore .modal-dialog {
  width: 90%;
  max-width: 700px;
  margin: auto;
  margin-top: 25px;
  padding: 0;
}
